.modal-close {
    margin-bottom: 25px;
}

.modal-content {
    h3,
    h5 {
        color: $font-color-light;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.modal-content:focus,
.modal-body:focus {
    box-shadow: none !important;
}

.modal-backdrop {
    background-color: $gray-50 !important;
}

@each $name in ('modal', 'b-modal') {
    @each $value, $percent in $percentages {
        .#{$name}-#{$value}-pw {
            .modal-dialog {
                width: $percent + 0% !important;
                max-width: $percent + 0% !important;
            }
        }
    }
}