@import '../../app/styles/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

@mixin dangler($color: $nav-tab-bg-active, $size: 7px) {
    border: solid $size transparent;
    border-top-color: $color;
    bottom: -(($size * 2) - 1px);
    content: " ";
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

@media (min-width: 992px) {
    #navbarTop .navbar-expand-lg .navbar-collapse {
        display: inline-flex !important;
        flex-basis: auto;
    }
}

#navbarTop,
.subnav {
    background-color: white;

    .navbar {
        margin: 0;
        padding: 0;
    }

    .navbar-nav {
        li {
            position: relative;
            text-align: center;
            background-color: $nav-tab-bg;
            border-left: 1px solid $nav-tab-border;
            border-right: 1px solid $nav-tab-border;

            > a {
                box-shadow: inset 0 -4px $nav-tab-border;
                overflow: hidden;
                position: unset;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:hover {
                    background-color: $orange-25;
                    box-shadow: inset 0 -4px $orange-75;
                    color: $orange;
                }
            }

            .dropdown-item.router-link-active:not(:hover):not(:focus):not(:active) {
                background-color: $blue-50 !important;
                color: white !important;
            }

            //&[data-action="account"] {
            //    > a[data-toggle="dropdown"] {
            //        &::after {
            //            content: none;
            //        }
            //
            //        &[aria-expanded="true"] {
            //            background-color: $orange;
            //            box-shadow: none;
            //            color: $orange-25;
            //        }
            //    }
            //
            //    .dropdown-menu .dropdown-item.active {
            //        background-color: $blue-50 !important;
            //        color: white !important;
            //    }
            //}

            &:not(.active) > a {
                &:not(:disabled):not(.disabled):active {
                    background-color: $orange-50;
                    border-color: $orange-50;
                }
            }

            &.admin {
                &.ancestor > a {
                    &:not(:hover) {
                        box-shadow: inset 0 -4px $teal;
                    }
                }
            }

            &.ancestor > a {
                font-weight: bold;

                &:not(:hover) {
                    box-shadow: inset 0 -4px $yellow;
                }
            }
        }

        &.nav-tabs {
            border-bottom: none;

            .nav-link {
                border: none;
                border-radius: 0;
                //border-top-left-radius: 0;
                //border-top-right-radius: 0;
            }
        }

        @media (min-width: 992px) {
            li {
                border-right: none;
            }
        }
    }
}

#navbarTop {
    border-bottom: 1px solid #ced4da;

    [data-intent="client-logo"] {
        max-width: 120px;
        max-height: 40px;
    }

    [data-intent="extra-features"] {
        width: 100% !important;
        background-color: white;
        border: none;

        a:not(.dropdown-item),
        [data-toggle="dropdown"] {
            color: $nav-tab-bg-active;

            &:hover {
                color: $nav-tab-hover;
            }
        }
    }

    .container-fluid {
        padding: 0;
    }

    .navbar-nav {
        font-size: 1rem;
        text-transform: uppercase;

        .nav-item {
            &:not([data-action="account"]) {
                min-width: 150px;
            }

            > a {
                padding: 15px 0.5rem !important;
                line-height: 20px;

                &:not([disabled]):not(:hover) {
                    background-color: $nav-tab-bg;
                    color: $nav-tab-bg-active;
                }
            }

            &.active > a:not([disabled]) {
                position: initial;
                box-shadow: none;
                background-color: $nav-tab-bg-active;
                color: #fff;

                &::after {
                    @include dangler();
                }
            }
        }

        &.nav-tabs {
            .nav-item:last-child {
                border-right: 1px solid $nav-tab-border;
            }
        }
    }
}

.navbar-brand {
    margin: 0 15px 0 15px;
    padding: 0;
    z-index: 999;

    img {
        height: auto;
        position: relative;
        //position: absolute;
        width: 190px;
        top: 11px;
        //height: 52px;
    }
}

.no-navigation {
    margin-bottom: -27px !important;

    .navbar-brand {
        img {
            top: 0;
        }
    }
}

.subnav {
    padding: 0;
    border-left: 1px solid $nav-tab-border;
    border-right: 1px solid $nav-tab-border;

    &.account,
    &.user {
        .navbar-nav {
            li {
                &.active > a {
                    background-color: $subnav-tab-bg-active;
                    color: $teal !important;
                    box-shadow: inset 0 -4px $teal;

                    &::after {
                        @include dangler($teal);
                    }
                }
            }

            &:not(.wizard) {
                li {
                    &:not(.active) {
                        & > a {
                            box-shadow: inset 0 -4px $teal-25;

                            &:hover,
                            &:active:not(:disabled):not(.disabled) {
                                background-color: $orange-25;
                                box-shadow: inset 0 -4px $orange-75;
                                color: $nav-tab-hover;
                            }

                            &:active:not(:disabled):not(.disabled) {
                                background-color: $orange-50;
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar-nav {
        font-size: 1rem;

        li {
            &:first-child {
                border-left: none;
            }

            a {
                padding: 10px 0.5rem !important;
                //line-height: 1.125rem;
            }

            &.active > a {
                position: initial;
                background-color: $subnav-tab-bg-active;
                color: $primary !important;
                box-shadow: inset 0 -4px $primary;


                &::after {
                    @include dangler();
                    z-index: $zindex-dropdown + 1;
                }

                &::before {
                    content: " ";
                    position: absolute;
                }
            }
        }

        &.nav-tabs {
            .nav-item {
                width: 100% !important;
                z-index: 1;
            }
        }

        &:not(.wizard) {
            li {
                background-color: white;

                &:not(.active) {
                    & > a {
                        //box-shadow: inset 0 -4px $gray-25;

                        &:hover,
                        &:active:not(:disabled):not(.disabled) {
                            background-color: $blue-25;
                            box-shadow: inset 0 -4px $blue-50;
                            color: white;
                        }

                        &:active:not(:disabled):not(.disabled) {
                            background-color: $blue-50;
                            box-shadow: inset 0 -4px $primary;
                        }
                    }
                }
            }

            .dropdown-menu {
                margin-top: 0 !important;
                z-index: $zindex-dropdown + 1;

                .dropdown-item:hover {
                    background-color: $blue-25;
                    color: inherit;
                }
            }
        }
    }
}

#navbarSupportedContent {
    background-color: white;
    z-index: 100;

    &.collapse.show {
        .dropdown-menu {
            position: absolute;
        }
    }

    .nav-item {
        border-top: 1px solid $nav-tab-border;
    }
}
