@import 'variables';

table {
    td, th {
        &.fit {
            white-space: nowrap;
            width: 1%;
        }
    }

    &.sorted {
        th {
            position: relative;

            a.sort {
                color: white !important;
                display: inline-block;
                margin-left: .66rem;
                position: absolute;
                right: auto;

                &.asc {
                    top: 1px;
                }

                &.desc {
                    bottom: 1px;
                }
            }
        }
    }

    &.widget-table {
        th {
            font-weight: lighter;
            text-transform: uppercase;
        }
    }

    .table-cell-min {
        width: 1%;
        white-space: nowrap;
    }

    .thead-teal {
        background-color: $teal;

        & th {
            border-color: $teal;
        }
    }
}
