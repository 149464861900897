@import '../variables';


.btn-md {
    height: $btn-md-height !important;
    line-height: initial !important;
    min-width: 150px !important;
}

button.disabled,
button:disabled {
    &, * {
        cursor: not-allowed !important;
    }
}

.btn {
    &.disabled, &:disabled {
        &:not([class*='btn-outline-']) {
            background-color: $gray-25 !important;
            border-color: transparent !important;
        }
        &[class*='btn-outline-'] {
            border-color: $gray-25;
            color: $gray-25;
        }
    }
}

//// TODO: are we abandoning bootstrap's primary, warning, etc. styles?
//.btn {
//    &-blue {
//        background-color: $blue-75 !important;
//        color: white !important;
//
//        &:hover {
//            background-color: $blue !important;
//        }
//
//        &:active {
//            color: $blue-25 !important;
//        }
//    }
//}

//.btn-arrow,
//.btn-arrow:focus {
//    border: none;
//    background-color: transparent;
//    outline: none;
//}


// Fix black text on lighter buttons
.btn:not(.btn-light):not(.btn-link):not(.btn-subaction):not([class*="btn-outline-"]):not([class*="select-"]) {
    color: white !important;
}

[class*="btn-outline-"] {
    border-width: 2px !important;

    &:not(:hover):not(:disabled):not(.disabled) {
        background-color: white !important;
    }

    &:hover {
        color: white !important;
    }
}

.detail-button {
    ~ .details {
        position: absolute;
        height: 100%;
        right: 0;
        text-align: center;
        top: 0;
        width: 1.5rem;

        .detail-icon,
        button.spinner {
            cursor: pointer;
            margin: auto;
            opacity: 1; // See if we can hide this before hover
            //position: absolute;
            //transition: all 0.2s linear 0s;
        }
    }

    &.small {
        font-size: 1.2rem;
    }
}

.select-group {
    .btn {
        background-color: transparent;
        border-color: $gray-50;

        &:hover {
            border-color: inherit;
        }

        &.active {
            color: white !important;
            z-index: inherit;
        }

        &.select-black {
            color: black;

            &:hover {
                background-color: $gray-25;
                //color: $blue;
            }

            &.active {
                background-color: $gray-75;
                border-color: $gray $gray-50 $gray-50 $gray;
            }
        }

        &.select-blue {
            color: $blue-75;

            &:hover {
                background-color: $blue-25;
                color: $blue;
            }

            &.active {
                background-color: $blue-75;
                border-color: $blue $blue-50 $blue-50 $blue;
            }
        }

        &.select-green {
            color: $green-75;

            &:hover {
                background-color: $green-25;
                color: $green;
            }

            &.active {
                background-color: $green-75;
                border-color: $green $green-50 $green-50 $green;
            }
        }

        &.select-orange {
            color: $orange;

            &:hover {
                background-color: $orange-25;
                color: $orange;
            }

            &.active {
                background-color: $orange-75;
                border-color: $orange $orange-50 $orange-50 $orange;
            }

            ~ .dropdown-menu {
                border-color: $orange;

                .dropdown-item {
                    color: $orange;

                    &:hover {
                        background-color: $orange-75;
                        color: white;
                    }
                }
            }
        }

        &.select-purple {
            color: $purple;

            &:hover {
                background-color: $purple-25;
                color: $purple;
            }

            &.active {
                background-color: $purple-75;
                border-color: $purple $purple-50 $purple-50 $purple;
            }
        }

        &.select-red {
            color: $red-75;

            &:hover {
                background-color: $red-25;
                color: $red;
            }

            &.active {
                background-color: $red-75;
                border-color: $red $red-50 $red-50 $red;
            }
        }

        &.select-teal {
            color: $teal-75;

            &:hover {
                background-color: $teal-25;
                color: $teal;
            }

            &.active {
                background-color: $teal-75;
                border-color: $teal $teal-50 $teal-50 $teal;
            }
        }

        &.select-yellow {
            color: $yellow-75;

            &:hover {
                background-color: $yellow-25;
                color: $yellow;
            }

            &.active {
                background-color: $yellow-75;
                border-color: $yellow $yellow-50 $yellow-50 $yellow;
            }
        }
    }
}

.filter-btn.selected {
    background-color: $green-75 !important;

    span,
    .svg-inline--fa {
        color: white !important;
    }
}

.btn-group-operators {
    z-index: 0;

    & .btn {
        flex: none !important;
        position: relative;
        top: -1px;
        padding: 6px 20px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid $gray-25;
        cursor: not-allowed;

        &.select-yellow,
        &.select-blue,
        &.select-green,
        &.select-yellow:hover,
        &.select-blue:hover,
        &.select-green:hover {
            color: $gray-25;
            background-color: white;
        }

        &:focus {
            box-shadow: none !important;
        }
    }

    &.active {
        .btn {
            &.select-yellow,
            &.select-blue,
            &.select-green {
                color: $gray-50;
            }

            &.select-yellow:hover,
            &.select-blue:hover,
            &.select-green:hover {
                color: $gray-75 !important;
                //background-color: unset;
                border-color: $gray-25;
                cursor: pointer;
            }

            &.select-blue {
                &.active,
                &.active:hover {
                    color: white !important;
                    background-color: $teal-50;
                    border-color: $teal-50;
                }
            }

            &.select-yellow {
                &.active:hover {
                    color: white !important;
                    background-color: $yellow-75;
                    border-color: $yellow-75;
                }
            }

            &.select-green {
                &.active:hover {
                    color: white !important;
                    background-color: $green-75;
                    border-color: $green-75;
                }
            }
        }
    }
}
