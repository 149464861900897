@import "variables"; // Main variables file - MUST be loaded FIRST to take precedence!

// Add new theme colors
$theme-colors: (
        //bright-blue: $brightblue,
        //dark-blue: $darkblue,
        //blue-25: $blue-25,
        //blue-10: $blue-10,
        //gray-75: $gray-75,
        //teal-25: $teal-25,
        //teal-50: $teal-50,
        //gray-50: $gray-50,
        //gray-25: $gray-25,
        //gray-10: $gray-10,
        //primary: $primary,
        //orange: $orange,
        //orange-75: $orange-75,
        //yellow: $yellow,
        //white: white,
        //base: $font-color-light
) !default;

// Bootstrap core components
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Overrides & enhancements
@import 'bootstrap/buttons';
@import 'bootstrap/cards';
@import 'bootstrap/dropdown';
@import 'bootstrap/forms';
@import 'bootstrap/modals';

// State-specific color utility
$colors: (
        //bright-blue: $brightblue,
        //dark-blue: $darkblue,

        //border-default: $border-color,
        //gray-75: $gray-75,
        //green: $green,
        //green-25: $green-25,
        //blue: $blue,
        //orange: $orange,
        //orange-75: $orange-75,
        //primary: $blue,
        //red: $red,
        //teal: $teal,
        //unthemed: $font-color,
        //yellow: $yellow,
        //yellow-75: $yellow-75,
        //yellow-25: $yellow-25,
        //white: white,

        transparent: transparent
);
@each $prop in (hover, focus, active) {
    @each $name, $color in $colors {
        .#{$prop}-#{$name}:#{$prop} {
            color: $color !important;
        }
        .#{$prop}-bg-#{$name}:#{$prop} {
            background-color: $color !important;
        }
        .#{$prop}-children-#{$name}:#{$prop} * {
            color: $color !important;
        }
        .#{$prop}-border-#{$name}:#{$prop} {
            border-color: $color !important;
        }
    }
}
@each $name, $color in $colors {
    svg.fill-#{$name} {
        fill: $color !important;
    }
    .bg-#{$name} {
        background-color: $color !important;
    }
    .border-#{$name}:not(:hover) {
        border-color: $color !important;
    }
    .show .show-#{$name} {
        color: $color !important;
    }
}

.col-padding-half {
    &:not(:first-child) {
        padding-left: ($grid-gutter-width / 4) !important;
    }
    &:not(:last-child) {
        padding-right: ($grid-gutter-width / 4) !important;
    }
}

.mb-n100 {
    margin-bottom: -100%;
}
.mt-n100 {
    margin-top: -100%;
}

//// Opacity utility
//$opacities: (
//        0: 0,
//        10: .1,
//        20: .2,
//        30: .3,
//        40: .4,
//        50: .5,
//        60: .6,
//        70: .7,
//        80: .8,
//        90: .9,
//        100: 1,
//);
//@each $prop, $abbrev in (opacity: o) {
//    @each $opacity, $amount in $opacities {
//        .#{$abbrev}-#{$opacity} {
//            #{$prop}: $amount !important;
//        }
//    }
//}
//// Font-size utility
//$font-sizes: (
//        1: 0.33rem,
//        2: 0.66rem,
//        3: 1.5rem,
//        4: 2rem,
//        5: 3rem,
//);
//@each $prop, $abbrev in (font-size: fs) {
//    @each $size, $scale in $font-sizes {
//        .#{$abbrev}-#{$size} {
//            #{$prop}: $scale !important;
//        }
//    }
//}

// Finally, import the rest of Bootstrap
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
//@import "~bootstrap/scss/bootstrap";

