@import '../app/styles/variables';

.associated-chart-data {
    color: $blue-75;

    .title {
        color: inherit !important;
    }
}
//.value {
//    &-greater {
//        color: $green-75;
//    }
//    &-less {
//        color: $red-75;
//    }
//}
