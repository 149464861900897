// Primary brand colors
//$darkblue: #001f33;
//$brightblue: #0099cc;

//$blue: #005587;
$blue: #001f33;
$blue-75: #4f7ba4;
$blue-50: #8aa5c2;
$blue-25: #c4d0df;
$blue-10: #e5eef3;
$blue-1: #f1faff;
$yellow: #f5b335;
$yellow-75: #fbc56c;
$yellow-50: #ffd89d;
$yellow-25: #ffebcd;
$yellow-10: #fff8ef;

// Secondary brand colors
//$teal: #0092b3;
$teal: #0099cc;
$teal-75: #41acc5;
$teal-50: #8bc7d8;
$teal-25: #c6e2eb;
$orange: #ed7900;
$orange-75: #f8994a;
$orange-50: #ffbb86;
$orange-25: #ffdcc1;

// Tertiary brand colors
$green: #6fa84f;
$green-75: #91b876;
$green-50: #b3cb9d;
$green-25: #d7e2ca;
$green-10: #f0f6ed;
$purple: #5f6db3;
$purple-75: #8088c2;
$purple-50: #a4a8d5;
$purple-25: #cdcfe8;
$red: #ce3438;
$red-75: #d86a5b;
$red-50: #e29684;
$red-25: #efc6ba;
$red-10: #faebeb;
$gray: #404040;
$gray-75: #707070;
$gray-50: #a0a0a0;
$gray-25: #cfcfcf;
$gray-15: #eeeeee;
$gray-10: #f2f2f2;
$gray-5: #f9f9f9;

$view-bg: #efefef;
$header-topbar-height: 6rem;
$nav-tab-bg: $blue-10; // TODO: can this be handled with standard Bootstrap vars?
$nav-tab-border: $blue-25; // TODO: can this be handled with standard Bootstrap vars?
$nav-tab-bg-active: $blue; // TODO: can this be handled with standard Bootstrap vars?
$nav-tab-hover: $orange; // TODO: can this be handled with standard Bootstrap vars?
$subnav-tab-bg-active: white;
$dropdown-menu-bg: rgba(0, 55, 113, 0.51); // NOT bootstrap
$dropdown-menu-arrow-bg: #7993b1; // NOT bootstrap
$disabled: #e9ecef;
$font-color: $gray;
$font-color-light: #666;
$h4-color: #777;
//$card-inner-bg: #f3f3f3;
$view-border-color: #efefef;

//$photo-size: 64px;
//$photo-border-size: 2px;

/***** Bootstrap overrides *****/
$primary: $blue !default;
$secondary: $teal !default;
$info: $teal !default;
$success: $green-75 !default;
$warning: $yellow-75 !default;
$danger: $red-75 !default;
$dark: $gray !default;
$light: $font-color-light !default;

$body-color: black;

$font-family-sans-serif: Roboto, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-awesome-family: "Font Awesome 5 Pro";
$font-size-base: 1.15rem;
//$headings-font-weight: normal !default;
//$headings-font-weight: 700 !default;
//$headings-color: $secondary !default;
$headings-color: $primary !default;
//$link-color: $primary !default;
//$table-accent-bg: $blue-secondary-25; /* blue striped tables? */
$table-dark-bg: $dark;
$body-bg: white !default;
$custom-control-label-color: $primary;

//$nav-link-height: 4rem;
//$navbar-padding-y: 0;
//$navbar-padding-x: 0.5em;
//$navbar-nav-link-padding-x: 0;
$navbar-light-color: $gray;

// Style anchor elements.
//$link-color: $primary !default;
$link-color: $secondary !default;
//$link-decoration: none !default;
//$link-hover-color: $blue !default;
$link-hover-decoration: none !default;

// Dropdowns
$dropdown-padding-y: 0 !default;
$dropdown-spacer: .125rem !default;
$dropdown-font-size: 1rem;
$dropdown-bg: $gray !default;
$dropdown-divider-bg: $gray-75 !default;
$dropdown-divider-margin-y: 0 !default;
$dropdown-link-color: white !default;
$dropdown-link-hover-color: $gray !default;
$dropdown-link-hover-bg: white !default;
$dropdown-item-padding-y: .5rem !default;
$dropdown-item-padding-x: .5rem !default;

// Backgrounds
$table-accent-bg: $blue-10;
$table-dark-bg: $dark;
$table-head-bg: $gray;

// Font Colors
$text-muted: $gray-75;
$text-white: white;

// Borders
$border-color: $gray-25;

// Heights
$btn-md-height: 30px;

// Modals
$modal-content-border-radius: 8px;
$modal-header-border-width: 0;
//$modal-header-border-color: transparent;
$modal-dialog-margin: 0 !default;

$percentages: (
    0: 0,
    5: 5,
    10: 10,
    15: 15,
    20: 20,
    25: 25,
    30: 30,
    35: 35,
    40: 40,
    45: 45,
    50: 50,
    55: 55,
    60: 60,
    65: 65,
    70: 70,
    75: 75,
    80: 80,
    85: 85,
    90: 90,
    95: 95,
    100: 100
);
