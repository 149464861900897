@import 'variables';

i[action] {
    cursor: pointer;
}

[action="clear"],
[action="exclude"] {
    color: $danger;

    &:hover {
        color: $danger;
    }
}

.add-icon,
.search-icon {
    right: 0.5rem;

    [action="add"],
    [action="search"] {
        &:hover {
            color: $green;
        }
    }

    [action="clear"] {
        color: $danger;

        &:hover {
            color: $red;
        }
    }
}

.add-control,
.edit-control,
.view-control,
.search-control,
.status-icon,
.status-control {
    [action="add"] {
        &:hover:not([class*='disabled']):not([class*='read-only']) {
            color: $green !important;
        }
    }

    [action="search"],
    [action="view"]:not([class*='danger']),
    [action="edit"]:not([class*='disabled']):not([class*='danger']) {
        color: $blue-75 !important;

        &:hover:not([class*='disabled']):not([class*='read-only']) {
            color: $blue !important;
        }
    }

    [action="clear"],
    [action="switch"] {
        color: $red-75 !important;

        &.unchecked {
            color: $red-75 !important;
        }

        &:hover:not([class*='disabled']):not([class*='read-only']) {
            color: $red !important;
        }
    }

    [action="view"],
    [action="edit"],
    [action="switch"] {
        &.checked {
            color: $green-75 !important;

            &:hover:not([class*='disabled']):not([class*='read-only']) {
                color: $green !important;
            }            
        }

        &.unchecked:not([class*='toggle-']) {
            color: $gray-75 !important;

            &:hover:not([class*='disabled']):not([class*='read-only']) {
                color: $green !important;
            }
            
            &.read-only {
                color: $gray-50 !important;
            }
        }        
    }
}

.admin {
    .edit-control,
    .status-control {
        [action="edit"]:not([class*='disabled']):not([class*='danger']),
        [action="switch"]:not([class*='disabled']):not([class*='danger']):not([class*='unchecked']) {
            color: $info !important;
            
            &.checked {
                color: $info !important;
    
                &:hover:not([class*='disabled']):not([class*='read-only']) {
                    color: $secondary !important;
                }            
            }  
        }
    }
}


.vue-tags-input ~,
input ~ {
    .add-icon,
    .search-icon {
        color: $gray-25;
    }
}

.social-action-indicator {
    align-items: center;
    color: white;
    display: flex;
    font-size: 1.25rem;
    font-weight: bold;
    justify-content: center;
    padding: .5rem .33rem;

    &.twitter {
        &-hashtag {
            background-color: $yellow;
        }

        &-mention {
            background-color: $teal;
        }

        &-term {
            background-color: $green;
        }
    }
}

i.warning {
    color: $yellow-75;
}

i.error {
    color: $red;
}

svg {
    border-radius: $border-radius;
}

svg[tabindex]:focus:not(.arrow-btn) {
    color: $green;
    filter: drop-shadow(1px 2px 2px #5eaeb7);
    box-shadow: none !important;
}

i[action="add"] {
    cursor: pointer;
}
