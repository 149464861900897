.highcharts-xaxis-labels {
    //max-width: 25%;

    span {
        min-width: 17rem;
        //width: 100%;
        text-overflow: initial;
        white-space: normal !important;
    }
}
