@import '../app/styles/variables';

.pb-pagination {
    font-size: 1.25rem;

    a, a [data-icon] {
        color: $link-color !important;
    }

    input[type=number] {
        background: $link-color;
        border: solid 2px transparent;
        border-radius: 1em;
        color: white;
        min-width: 1.95em;
        text-align: center;
        -moz-appearance: textfield;

        &:focus {
            background: white;
            border-color: $link-color;
            color: $link-color;
            outline: none;
        }

        &::placeholder {
            color: $gray-50;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    //li:not(:last-child) {
    //    margin-right: 2rem !important;
    //}

    &[disabled] {
        cursor: not-allowed;
        //opacity: 0.5;
        pointer-events: none;
    }
}
