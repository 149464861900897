// App-wide style variables
@import './styles/variables.scss';

a {
    &.disabled, &[disabled] {
        color: $text-muted !important;
        cursor: not-allowed;
    }
}

body {
    min-height: 100% !important;
    margin: 0;
    padding: 0;
}

//header.row {
//    padding: 25px 0;
//}

html {
    font-size: 12px;
    height: 100% !important;
    overflow-y: scroll;
    -webkit-tap-highlight-color: transparent;
}

header {
    &.topbar {
        height: $header-topbar-height;
    }
}

//img.logo {
//    height: 5.5rem;
//}

//header {
//    &.topbar {
//        background-color: $darkblue;
//        color: white;
//    }
//}

//*:focus {
//    outline: none;
//}

//#appView {
//    overflow: hidden;
//}

.center-vertical {
    top: 50%;

    &:not( span[ class*='spinner' ] ) {
        transform: translateY(-50%);
    }
}

.container-fluid {
    max-width: 1170px;
}

.highcharts-tooltip {
    .footer {
        border-top: solid 1px #cccccc;
        margin-top: .5rem;
        white-space: normal !important;
        width: 100%;
    }
}

.lh-i {
    line-height: initial !important;
}

.list-piped {
    .list-inline-item {
        &:not(:last-child) {
            &::after {
                content: "";
                border-right: solid 1px $teal;
                margin-left: $list-inline-padding;
            }
        }

        &:not(:first-child) {
            margin-left: -0.7ex;
        }
    }
}

//.min-content-height {
//    min-height: 1000px;
//}

.modal.show, body.modal-open {
    padding-right: inherit !important;
}

// Newspaper-style column layouts
.newspaper-columns {
    //column-gap: $grid-gutter-width * 2;
    column-gap: map-get($spacers, 5);
    text-align: justify;

    &[columns="2"] {
        column-count: 2;
    }
}


.opacity {
    &-0 {
        opacity: 0;
    }

    &-50 {
        opacity: 0.5;
    }
}

.overflow-ellipsis {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.pointer:hover {
    cursor: pointer;
}

.pointer-ignore {
    pointer-events: none;
}

.report-print header {
    display: none;
}

.saturation {
    &-0 {
        filter: saturate(0%);
    }
}

.snap {
    &-left {
        left: 0;
    }

    &-right {
        right: 0;
    }
}

.text-fill-white {
    -webkit-text-fill-color: white !important;
}

.text-outline {
    -webkit-text-stroke-width: 1px;
    -webkit-text-fill-color: transparent;
}

.text-italic {
    font-style: italic !important;
}

.text-size {
    &-sm {
        font-size: .66rem;
    }

    &-md {
        font-size: .85rem;
    }
}

.visibility-hidden {
    visibility: hidden;
}

// App-wide styles
//@import './styles/btn-arrow';
@import './styles/flex';
@import './styles/highcharts';
@import './styles/icons';
//@import './styles/lists';
@import './styles/tables';
@import './styles/tooltips';
//@import './styles/vue-tags-input';


