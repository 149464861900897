@import '../variables';

.card {
  border-color: $border-color !important;
}

.card-inner-body {
  background-color: $gray-10;
}

.card-shadow {
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.045) !important
}
