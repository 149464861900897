@import '../app/styles/variables';

input[data-action="update-build-name"] {
    border: solid 1px transparent !important;
    color: $blue;
    height: calc(.75em + 1rem + 1px);
    //width: auto;

    &:hover:not(:focus) {
        background-color: $blue-10;
    }

    &:focus {
        border-color: $success;
        outline: none;
    }
}

//.powered-by {
//    bottom: 0;
//    left: 0;
//    position: absolute;
//}

.report-container {
    min-height: 70rem;
}

.report-section {
    svg.index-indicator {
        width: 20px;
        height: 20px;
        overflow: visible;

        circle {
            fill: rgba($gray, 0.5);
            stroke: rgba($gray, 0.6);
        }

        &.high-index {
            circle {
                fill: rgba($green, 0.5);
                stroke: rgba($green, 0.6);
            }
        }

        &.low-index {
            circle {
                fill: rgba($red, 0.5);
                stroke: rgba($red, 0.6);
            }
        }
    }

    #characteristics {
        & .card,
        & .card-body {
            border: none !important;
        }

        & .card.topics,
        & .card-body {
            background-color: transparent !important;
        }
    }

    // Summary info modal - TODO: is this a reusable style that should live at a higher level?
    #summaryInfo {
        .modal-header {
            border-bottom: none;
        }

        .modal-content {
            background-color: $blue-25;
        }
    }

    .brand-logo {
        max-height: 35px;
        height: 100vh;
    }

    .chart-display {
        .additional-info {
            //background-color: $blue-10;
            min-height: 8.75rem;
            //overflow: hidden;

            &:empty {
                background-color: transparent !important;
            }
        }

        .social-action-label {
            max-width: 15em;
            overflow: hidden;
        }
    }

    .highcharts-container,
    [data-highcharts-chart] {
        //margin: 0 auto;
        overflow: visible !important;
    }

    .highcharts-title,
    .highcharts-subtitle,
    .overflow-ellipsis {
        overflow: hidden !important;
        //text-align: center;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
    }

    .header-detail {
        color: $blue-50;
    }

    .header-title {
        font-size: 1.25rem;
    }

    .rank-label {
        color: white;
        background-color: $gray-75;
        border-radius: 100%;
        //height: 1.25em;
        text-align: center;
        //width: 1.25em;
    }

    .section-title {
        color: $blue-75;
    }

    .select-group {
        z-index: 0;
    }

    .value {
        &-greater {
            color: $success;
        }

        &-less {
            color: $danger;
        }
    }

    .wedge-chart-display {
         color: $gray-75;
         font-weight: 800;
         /*font-size: .833rem;*/
         font-size: 1rem;

         .axis-labels {
             border-top: 1px solid $gray-75;
             /*margin-bottom: 10px;*/
             // margin-top: -1px;
             position: relative;

             div[data-intent] {
                 font-size: 10px;
                 font-weight: 600;
                 position: absolute;
             }

             [data-intent="median"] {
                 left: 50%;
                 transform: translateX(-50%);
             }

             [data-intent="max"] {
                 left: 100%;
                 transform: translateX(-100%);
             }
         }

         .fact-description {
             min-height: 3em; // Ensures two lines of space for each fact
         }

         .svg-container {
             height: auto;
             //overflow: hidden;
             position: relative;
             width: 100%;
         }

         .user-indicator {
             bottom: -2px;
             position: absolute;
             transform: translateX(-50%);
         }
     }
}
