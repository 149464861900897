@import '~bootstrap/scss/variables';

form {
    font-size: 1.2rem;

    label {
        color: $font-color-light;
    }

    .form-group.required legend:not(:empty):after,
    label.required::after {
        content: " *";
        color: $orange;
        position: inherit;
        right: 0;
        top: -15px;
    }

    input:focus,
    select:focus,
    textarea:focus {
        border-color: $teal-75;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 85, 135, 0.25) !important;
    }

    input {
        &::placeholder {
            color: $gray-25 !important;
            font-style: italic;
        }

        &.search {
            padding-right: 30px;
        }

        &.border-teal::placeholder {
            color: $teal-25 !important;
        }
    }

    .icon-control {
        input {
            height: ($font-size-base * $line-height-base + $custom-control-indicator-size) / 2;
            left: 0;
            opacity: 0;
            position: absolute;
            width: $custom-control-indicator-size;
            z-index: -1; // Put the input behind the label so it doesn't overlay text

            ~ label {
                color: $custom-control-label-color;
                cursor: $custom-control-cursor;
                margin-bottom: 0;
                position: relative;
                vertical-align: top;

                //@extend .custom-control-label;

                &::before {
                    background-color: transparent !important;
                    border: none !important;
                    box-shadow: none !important;
                    //color: $success !important;
                    color: inherit !important;
                    display: block;
                    font-family: $font-awesome-family;
                    left: -($custom-control-gutter + $custom-control-indicator-size);
                    pointer-events: auto !important;
                    position: absolute;
                    top: inherit !important;

                    width: $custom-control-indicator-size;
                    height: $custom-control-indicator-size;
                    content: "";
                }

                &::after {
                    display: none !important;
                }
            }

            //&[type=checkbox],
            &[type=radio] {
                &:not(:checked) ~ label::before {
                    content: '\f111';
                    font-weight: 500;
                }

                &:checked ~ label::before {
                    content: '\f058';
                    font-weight: 900;
                }
            }

            // Checkboxes do not exist in the PersonaBuilder cinematic universe
            &[type=checkbox] {
                &:not(:checked) ~ label::before {
                    content: '\f0c8';
                    font-weight: 500;
                }

                &:checked ~ label::before {
                    content: '\f14a';
                    font-weight: 900;
                }
            }

            &:disabled {
                ~ label {
                    &, &::before {
                        color: $gray-25 !important;
                    }
                }
            }
        }

        //&[color="green"],
        &[color="success"] {
            input ~ label::before {
                color: $success !important;
            }
        }

        &[color="primary"] {
            input ~ label::before {
                color: $primary !important;
            }
        }

        //&[color="teal"],
        &[color="secondary"] {
            input ~ label::before {
                color: $secondary !important;
            }
        }

        &-lg {
            .custom-control {
                line-height: ($custom-control-indicator-size * 2);
                padding-left: ($custom-control-gutter + ($custom-control-indicator-size * 2));
            }

            input ~ label::before {
                font-size: ($custom-control-indicator-size * 2) !important;
                left: -($custom-control-gutter + ($custom-control-indicator-size * 2)) !important;
            }
        }
    }

    .is-invalid {
        color: $red !important;
    }

    //label {
    //    &.is-invalid {
    //        font-size: 1rem !important;
    //    }
    //}

    :deep() .custom-file-label.is-invalid:not(:before):not(:after),
    :deep() .custom-file-input.is-invalid,
    .form-control.is-invalid,
    .form-control.is-invalid:focus,
    input.is-invalid:-webkit-autofill {
        box-shadow: 0 0 0 1000px $red-25 inset;
        -webkit-box-shadow: 0 0 0 1000px $red-25 inset;
        -webkit-text-fill-color: $red !important;
    }

    :deep() .custom-file-label,
    .form-control {
        &.is-invalid,
        &.is-invalid:focus {
            background-color: $red-25 !important;
            background-image: none !important;

            &::placeholder {
                color: $red-50 !important;
            }
        }
    }

    .card {
        &.is-invalid {
            border-color: $red !important;
        }
    }

    .input-group > .form-control:not(:last-child) {
        border-top-right-radius: $border-radius !important;
        border-bottom-right-radius: $border-radius !important;
    }

    .input-append-transparent {
        position: absolute;
        cursor: pointer;
    }

    //.custom-control-input:checked ~ .custom-control-label::before {
    //    color: #fff;
    //    border-color: $green-75;
    //    background-color: $green-75;
    //}
    //
    //.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    //    color: #fff;
    //    background-color: $green;
    //    border-color: $green;
    //}
    //
    //.custom-radio {
    //    form input:focus {
    //        border-color: $green-25;
    //    }
    //}
}

.admin {
    form label,
    form .icon-control input ~ label {
        color: $info !important;
    }
}
