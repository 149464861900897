@import '../variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/utilities';

.dropdown-menu {
    max-height: 75vh;
    overflow: auto;
    text-transform: none;
}

// Custom style for a dropdown with a triangular tab pointing to its toggle
.dropdown-bubble {
    .dropdown-item {
        color: $gray;

        &:hover {
            background-color: $blue-75;
            color: white;
        }

        &[disabled] {
            &:hover {
                background-color: $gray-75;
                color: $gray-10 !important;
            }
        }

        //&.active {
        //    background-color: $blue-25 !important;
        //    color: white !important;
        //    cursor: none;
        //}

        z-index: +1;
    }

    .dropdown-menu {
        //margin-top: 0 !important;
        $arrow-size: 6px;
        $arrow-indent: 1rem;
        $border-color: $blue-50;
        background-color: white;
        border-color: $border-color;
        border-radius: $border-radius-sm !important;

        &.arrow-top {
            margin-top: $arrow-size;

            &::before,
            &::after {
                border: solid $arrow-size transparent;
                border-bottom-color: $border-color;
                content: '';
                display: block;
                left: $arrow-indent;
                position: absolute;
                right: auto;
                top: -($arrow-size * 2);
                transform: translateX(-33%)
            }

            &::before {
                border-bottom-color: $blue-50;
                transform: translateY(-1px) translateX(-33%);
            }

            &::after {
                border-bottom-color: white;
            }

            &.dropdown-menu-end {
                &::before,
                &::after {
                    left: auto;
                    right: $arrow-indent;
                    transform: translateX(33%)
                }

                &::before {
                    transform: translateY(-1px) translateX(33%);
                }
            }
        }
    }
}

.dropdown-menu {
    .item-secondary {
        &:not(:hover) {
            color: $teal;
        }

        &:hover {
            background-color: $teal;
        }
    }

    .item-danger {
        &:not(:hover) {
            color: $red;
        }

        &:hover {
            background-color: $red;
            color: $red-25;
        }
    }
}
