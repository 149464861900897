@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '../app/styles/variables.scss';

.pb-spinner {
    height: 5rem;
    width: 5rem;

    &-container {
        &.fill-space {
            min-height: 50rem;
        }
    }

    &-inner, &-outer {
        display: block;
        height: auto;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &-inner {
        //padding: 1.125rem 1.25rem;
        padding: 1.25rem;
        //transform: translate(-50%, -50%);
        //animation: 1.25s spin infinite ease-in-out;
    }

    &-text {
        //color: $teal;
        font-size: 2.25rem;
    }
}

.overlay-wrapper {
    .pb-spinner-container {
        z-index: 1;
    }

    .pb-spinner-frame {
        background-color: white;
        padding: 10px;
        border: 1px solid $blue-75;
        border-radius: $border-radius;
    }
}

//@keyframes spin {
//    to {
//        transform: rotate(360deg);
//    }
//}
